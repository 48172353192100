$(
  (function () {
    const references = $("[data-toggle='si-tooltip']");

    const popper = (placement, button, tooltip) => {
      Popper.createPopper(button, tooltip, {
        placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ],
      });
    };

    $(references).each(function () {
      const reference = this;
      const popover = $(`${$(reference).attr("data-target")}`);
      const placement = $(reference).attr("data-placement");

      popper(placement, reference, popover[0]);
    });

    $(document).on("click touchend", function (e) {
      const target = $(e.target);

      $(references).each(function () {
        const reference = this;
        const action = $(reference).attr("data-action");
        const popover = $(`${$(reference).attr("data-target")}`);

        if (action === "click") {
          if (target.is(popover) || popover.find(target).length !== 0) {
            return;
          }

          if (
            target.is($(reference)) ||
            $(reference).find(target).length !== 0
          ) {
            if (!popover.hasClass("si-tooltip__content--show")) {
              popover.addClass("si-tooltip__content--show");
              return;
            }
          }
          popover.removeClass("si-tooltip__content--show");
        }
      });
    });
  })()
);
